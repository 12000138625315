
<template>
<div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                  <div class="row">
                      <div class="col-sm-6 pb-3">
                        <h5 class="card-title">Notification List</h5>
                      </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    <span class="mr-1">Display</span>
                                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                          <div class="form-inline float-sm-right">
                            <div id="tickets-table_filter" class="dataTables_filter mr-sm-2 text-md-right">
                                <label class="d-inline-flex mb-0 align-items-center">
                                    <span class="mr-2 d-none d-sm-inline">Search:</span>
                                    <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm"></b-form-input>
                                </label>
                            </div>
                            <b-dropdown variant="light" size="sm" right>
                              <template slot="button-content">
                                 <i class="mdi mdi-filter-variant"></i>
                              </template>
                               <b-dropdown-item-button @click="selectAllRows()"><i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'DeSelect All' : 'Select All' }}</b-dropdown-item-button>
                              <b-dropdown-item-button @click="confirmDelete()" ><i class="mdi mdi-delete-forever-outline"></i> Delete</b-dropdown-item-button>
                            </b-dropdown>
                          </div>
                        </div>
                    </div>

                    <!-- Table -->
                    <div class="table-responsive mb-0">
                        <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class="" ref="selectableTable" :items="notifications" :fields="columns" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                            <template v-slot:cell(check)="data">
                                <div class="custom-control custom-checkbox text-center">
                                    <input v-model="selected" type="checkbox" :value="data.item.id" class="custom-control-input" :id="`checkorder${data.item.id}`" />
                                    <label class="custom-control-label" :for="`checkorder${data.item.id}`">{{data.item.id}}</label>
                                </div>
                            </template>
                            <template v-slot:cell(title)="data">
                                <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                                    <span @click="navigateTo(data.item)" class="text-dark font-sm">{{ data.item.title }}</span>
                                    <span v-if="!data.item.is_seen" class="badge badge-soft-info">new</span>
                                </h5>
                            </template>
                            <template v-slot:cell(message)="data">
                                <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                                    <span @click="navigateTo(data.item)" class="text-dark font-sm">{{ data.item.text }}</span>
                                </h5>
                            </template>
                            <template v-slot:cell(date)="data">
                                <span class="cursor-pointer" @click="navigateTo(data.item)">{{data.item.created_at | date_ago}} </span>
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {

  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: "ID"
          },
          {
            key: "title",
            sortable: true
          },
          {
            key: "Message",
            sortable: true
          },
          {
              key: "date",
              label: "Date",
              sortable: true
          },
      ]
    }
  },
  computed: {
    rows() {
        return this.notifications.length;
    },
    notifications() {
      return this.$store.state.notification.notifications
    },
  },
  methods: {
    navigateTo(notif){
      if(!notif.is_seen){
        this.$store.dispatch("notification/markNotifAsRead", notif.id);
      }
      this.$router.push(`/${notif.url}`).catch(() => {});
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.notifications.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    fetchNotifications() {
      this.$store.dispatch("notification/fetchNotifications")
    },
    confirmDelete() {
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      let text = `You are about to delete ${this.selected.length} notification${this.selected.length > 1 ? 's':'' }`;
      Swal.fire({
        title: "Are you sure?",
        text: text,
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          const formData = new FormData()
          formData.append('data', JSON.stringify({items: this.selected}))
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post('/notifications/delete',formData)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.alertSuccess(response.data.success);
              this.fetchNotifications()
            }else{
              this.alertError(response.data.error)
            }
          }).catch(error => { console.log(error) })
        }
      });
    },
    onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },
  },
  mounted() {
    this.totalRows = this.notifications.length;
  },
  created(){
    this.fetchNotifications()
  },
}

</script>

<style lang="scss">
.font-sm{
  font-size:13px;
}
</style>
